body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ant-collapse>.ant-collapse-item>.ant-collapse-header>.ant-collapse-header-text {
  text-align: start;
  width: 100%;
  font-weight: 700;
  font-size: 36px;
  line-height: 42.96px;
  font-family: SF Pro, sans-serif;

  @media only screen and (max-width: 640px) {
    font-size: 24px;
    text-align:center;
  }
}

.ant-collapse .ant-collapse-content {
  color: #454141;
  font-size: 16px;
  border-top: none;
  text-align: start;
  @media only screen and (max-width: 640px) {
    text-align:center;
  }
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  background-color: #F1F2F3;
  border-bottom: 2px solid rgba(0, 40, 69, 0.5);
  width: 100%;
  color: gray;
}
.ant-collapse > .ant-collapse-item-active > .ant-collapse-header {
  background-color: #F1F2F3;
  border-bottom: none;
  width: 100%;
  color: rgba(0, 0, 0, 0.88);
}

.ant-collapse .ant-collapse-content {
  background-color: #F1F2F3;
  font-family: SF Pro, sans-serif;
  font-size: 16px;
  font-weight: 590;
  line-height: 19.09px;
  border-top: none;
  border-bottom: 2px solid #002845;
  text-align: start;
  width: 100%;
  @media only screen and (max-width: 640px) {
    text-align:center;
  }
}

.ant-collapse {
  color: rgba(0, 0, 0, 0.88);
  line-height: 1.5714285714285714;
  list-style: none;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  background-color: #F1F2F3;
  margin: 0 2vh 0 0;
  width: 100%;
  border: none;

}

.ant-collapse .ant-collapse-content>.ant-collapse-content-box {
  padding: 16px 12px;
}

.ant-collapse>.ant-collapse-item:last-child>.ant-collapse-header {
  border-radius: 0px;
}

.ant-collapse>.ant-collapse-item:last-child {
  border-radius: 0px;
}

.ant-collapse .ant-collapse-item:last-child>.ant-collapse-content {
  border-radius: 0px;
}

.ant-collapse .ant-collapse-content>.ant-collapse-content-box {
  padding: 0px;
}

.ant-collapse>.ant-collapse-item.ant-collapse-no-arrow>.ant-collapse-header {
  padding-inline-start: 0px;
}

.ant-collapse>.ant-collapse-item {
  border-bottom: 0px;
}