.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #F1F2F3;
  width: auto;
  /* /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-container-free-mode > .swiper-wrapper {
  transition-timing-function: linear;
}

.swiper-container-free-mode > .swiper-wrapper {
  transition-timing-function: linear;
}
